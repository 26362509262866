import React from 'react'
import { Link } from 'gatsby';
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import Photo from '../Photo'


class RichText extends React.Component {
    componentDidMount() {
    }

    getParentUrl(itemType, serviceCategory) {
        switch (itemType) {
            case 'transformation': return "/promeny";
            case 'service': return "/sluzby-a-cenik";
            case 'service_item': return "/sluzby-a-cenik/" + {serviceCategory};
            default: return ""
        }
    }

    render() {

        const richTextData = this.props.data; // rich text element
        const serviceCategory = richTextData.serviceCategory;

        const RichTextBlock = (
            <RichTextElement
                value={richTextData.value}
                links={richTextData.links}
                resolveLink={(link, domNode) => {
                    const parentUrl = this.getParentUrl(link.type, serviceCategory);
                    return (
                        <Link to={`${parentUrl}/${link.url_slug}`}>
                            {domNode.children[0].data}
                        </Link>
                    )
                }}
                images={richTextData.images}
                resolveImage={image => {
                    return (
                        <Photo data={image} key={image.url} />
                    )
                }}
                linkedItems={richTextData.modular_content}
                resolveLinkedItem={linkedItem => {
                    var photos = [];
                    const type = linkedItem.system.type;
                    switch (type) {
                        case 'component_images_wrapper': {
                            return (
                                <div className="photos-wrapper">
                                    {linkedItem.elements.photos.value.forEach(photo => {
                                        photos.push(<Photo data={photo} key={photo.url} caption={linkedItem.elements.description.value == "" ? "" : "hide"} />)
                                    })}
                                    {photos}
                                    <div className="photos-wrapper__description">
                                        {linkedItem.elements.description.value}
                                    </div>
                                </div>
                            )
                        }
                        case 'component_text_and_image': {
                            return (
                                <div className="text-and-image row">
                                    <div className="text-and-image__text col-lg-8">
                                        <RichTextElement
                                            value={linkedItem.elements.text.value} />
                                    </div>
                                    <div className="text-and-image__image col-lg-4">
                                        <Photo data={linkedItem.elements.image.value[0]} key={linkedItem.elements.image.value[0].url} caption={"hide"} />
                                    </div>
                                </div>
                            )
                        }
                        default:
                            return null;
                    }
                }}
            />
        )

        return (
            <div>
                {RichTextBlock}
            </div>
        )
    }
}

export default RichText